.videoCont {
    cursor: move;
    position: relative;
    line-height: 0;
}

.fullscreen {
    cursor: default;
}

.videoCont canvas, .videoCont video {
    background-color: black;
}

.minBtn {
    position: absolute;
    z-index: 1;
    color: white;
    top: 20px;
    right: 70px;
    background-color: #0000003d;
    display: none;
    width: 40px;
    height: 40px;
    padding: 0.1rem;
}

.maxBtn {
    z-index: 1;
    color: white;
    top: 0;
    background-color: #0000008a;
    width: 25px;
    height: 25px;
    min-height: 25px;
}

.minBtn svg {
    position: relative;
    top: -11px
}

.actionBtn {
    position: absolute;
    z-index: 1;
    color: white;
    top: 20px;
    right: 20px;
    background-color: #0000003d;
    display: none;
    width: 40px;
    height: 40px;
    padding: 0.1rem;
}

.fullscreenBtn {
    position: absolute;
    z-index: 1;
    color: white;
    bottom: 20px;
    right: 20px;
    background-color: #0000003d;
    display: none;
    width: 40px;
    height: 40px;
    padding: 0.1rem;
}

.actionBtn:hover, .fullscreenBtn:hover, .minBtn:hover, .maxBtn:hover {
    background-color: #00000018;
}

.maxBtn:hover {
    background-color: #00000018;
    color: #0000008a;
}

.videoCont:hover .actionBtn, .videoCont:hover .fullscreenBtn, .videoCont:hover .minBtn {
    display: block;
}

.mobile {
    background-color: #00000018;
    display: block;
}