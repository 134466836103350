.CodeMirror {
    /* height: calc(100vh - 85px) !important; */
    /* height: calc(68vh); */
    height: calc(100% - 30px);
}

.CodeMirror.noFile {
    background-color: #263238;
    color: white;
}

.editor-title {
    height: 25px;
}

div.editor-container {
    width: calc(100% - 200px);
}

div.editor-container div.editor-title {
    background-color: rgba(38, 50, 56, 0.79);
    color: yellow;
    padding: 0 10px;
    font-weight: 200;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
}

.CodeMirror-focused div.CodeMirror-cursors {
    visibility: visible !important;
    animation: blinker 1s linear 0.5s infinite;
}

@keyframes blinker {
    50% {
      opacity: 0;
    }
}

.CodeMirror:not(.CodeMirror-focused) .CodeMirror-cursor {
    visibility: visible !important;
    border-left: 1px solid #ffffffae;
}

.CodeMirror-selected { background: #d7d4f0; }

.react-codemirror2.code-mirror-wrapper {
    height: 100%;
}