/**
 * Modfied version of [termynal.js](https://github.com/ines/termynal/blob/master/termynal.css).
 *
 * @author Ines Montani <ines@ines.io>
 * @version 0.0.1
 * @license MIT
 */
 .react-terminal-wrapper {
  height: 200px;
  width: 100%;
  background: #252a33;
  color: #eee;
  font-size: .9rem;
  font-family: 'Fira Mono', Consolas, Menlo, Monaco, 'Courier New', Courier, monospace;
  /* border-radius: 4px; */
  padding: 10px 10px 10px;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  letter-spacing: -.04rem;
  box-shadow: 0 1px 0 #525b61 inset;
 }

.react-terminal {
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.react-terminal-wrapper.react-terminal-light {
  background: #ddd;
  color: #1a1e24;
}

.react-terminal-wrapper.react-terminal-light:after {
  color: #D76D77;
}

.react-terminal-line {
  white-space: pre;
  position: relative;
}

.react-terminal-line:before {
  /* Set up defaults and ensure empty lines are displayed. */
  content: '';
  display: inline-block;
  vertical-align: middle;
  color: #a2a2a2;
}

.react-terminal-light .react-terminal-line:before {
  color: #D76D77;
}

.react-terminal-input:before {
  margin-right: .4em;
  content: attr(data-wd);
}

.react-terminal-input[data-terminal-prompt=""]:before {
  margin-right: .0em;
}

.react-terminal-input[data-terminal-prompt]:before {
  content: attr(data-terminal-prompt);
}

.react-terminal-wrapper .react-terminal-active-input:after {
  content: '';
  width: .6rem;
  display: inline-block;
  height: 90%;
  position: absolute;
  background-color: transparent;
  border: .1rem solid white;
  font-family: monospace;
}

.react-terminal-wrapper:focus-within .react-terminal-active-input:after {
  content: '';
  width: .6rem;
  display: inline-block;
  height: 90%;
  position: absolute;
  background-color: white;
  font-family: monospace;
  -webkit-animation: blink 1s infinite;
          animation: blink 1s infinite;
}

.react-terminal-wrapper .react-terminal-input[data-terminal-prompt=""]:after {
  content: '';
  width: .6rem;
  display: inline-block;
  height: 90%;
  position: absolute;
  background-color: transparent;
  border: .1rem solid white;
  font-family: monospace;
  -webkit-animation: blink 1s infinite;
          animation: blink 1s infinite;
}

.react-terminal-light .react-terminal-wrapper:focus-within .react-terminal-active-input:after {
  background-color: black;
}

/* Cursor animation */

@-webkit-keyframes blink {
  50% {
      opacity: 0;
  }
}

@keyframes blink {
  50% {
      opacity: 0;
  }
}

.terminal-hidden-input {
    position: fixed;
    left: -1000px;
}

/* .react-terminal-progress {
  display: flex;
  margin: .5rem 0;
}

.react-terminal-progress-bar {
  background-color: #fff;
  border-radius: .25rem;
  width: 25%;
}

.react-terminal-wrapper.react-terminal-light .react-terminal-progress-bar {
  background-color: #000;
} */
